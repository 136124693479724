import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../reusable/button";
import restaurantsClubLogo from "@images/restaurants-club-logo.svg";
import restaurantsClubLogoWhite from "@images/restaurants-club-logo-white.png";
import ChevronDown from "@images/chevron-down-white.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";

type MenuVoice = {
    name: string;
};

type TranslateRoutes = {
  it: string;
  en: string;
};

type DropDownLinks = {
    name: string;
    link: string;
};

type Choice = {
    lng: string;
    icon: string;
    language: string;
};

export const HamburgerMenu = () => {
    const [openHamburger, setOpenHamburger] = useState(false);
    const [openDropDownMenu, setOpenDropDownMenu] = useState(false);
    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const [currentLanguageIcon, setCurrentLanguageIcon] = useState(italianLanguageIcon);
    const { t, i18n } = useTranslation();
    const dropDownRef = useRef<HTMLDivElement>(null);
    const languageRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const changeLanguage = (choice: Choice) => {
        i18n.changeLanguage(choice.lng);
        setCurrentLanguageIcon(choice.icon);
        setLanguageModalOpen(false);
    };

    useEffect(() => {
      // To avoid the redirect. TODO: Solve in the future
      const path = window.location.pathname.split('/').pop();
      const menuVoices = t("translateRoutes", { returnObjects: true }) as TranslateRoutes[];
    
      const matchedVoiceIndex = menuVoices.findIndex((voice) => 
        (i18n.language === 'it' && voice.en === path) || (i18n.language === 'en' && voice.it === path));
    
      if (matchedVoiceIndex !== -1) {
        const translatePath = i18n.language === 'it' ? menuVoices[matchedVoiceIndex].it : menuVoices[matchedVoiceIndex].en;
        navigate(`/${translatePath}`);
      }
    }, [i18n.language]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
                setOpenDropDownMenu(false);
            }
            if (languageRef.current && !languageRef.current.contains(event.target as Node)) {
              setLanguageModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full fixed top-0 flex justify-between items-center p-3 bg-white z-50 xmd:hidden lg:hidden xl:hidden 2xl:hidden">
          <img src={restaurantsClubLogo} alt="Restaurants Club Logo" />
          <div onClick={() => setOpenHamburger(!openHamburger)} className="relative z-50 flex flex-col gap-1 cursor-pointer">
            <span className={`w-6 h-1 rounded bg-gray600 transition-transform duration-300 ease-in-out ${openHamburger && 'rotate-45 translate-y-2 bg-white'}`}></span>
            <span className={`w-6 h-1 rounded bg-gray600 transition-opacity duration-300 ease-in-out ${openHamburger ? 'opacity-0' : 'opacity-100'}`}></span>
            <span className={`w-6 h-1 rounded bg-gray600 transition-transform duration-300 ease-in-out ${openHamburger && '-rotate-45 -translate-y-2 bg-white'}`}></span>
          </div>
          <div className={`w-full fixed z-40 flex flex-col gap-10 min-h-dvh bg-primary500 right-0 top-0 p-3 transform transition-transform duration-300 ease-in-out ${openHamburger ? 'translate-x-0' : 'translate-x-full'} `}>
            <img className="w-[300px]" src={restaurantsClubLogoWhite} alt="Restaurants Club Logo" />
            <nav className="w-full flex flex-col gap-10 items-start mt-[100px] font-poppins text-xl text-white font-semibold pl-20">
              {(t("navBarMenuVoices", { returnObjects: true }) as MenuVoice[]).map((voice, index) => (voice.name.toLowerCase() === "blog" ? (
                <Link to="https://blog.restaurants.club/blog/#" key={index} className="uppercase" onClick={() => setOpenHamburger(!openHamburger)}>
                  {voice.name}
                </Link>
                ) : (
                <Link to={`/${voice.name.toLowerCase()}`} key={index} className="uppercase"  onClick={() => setOpenHamburger(!openHamburger)}>
                  {voice.name}
                </Link>
                )
              ))}
              <div ref={dropDownRef}>
                <button onClick={() => setOpenDropDownMenu(!openDropDownMenu)} className="uppercase">
                  {t("navBarButton.dropDownMenu")}
                </button>
                {openDropDownMenu && (
                  <nav className="flex flex-col gap-1 p-1">
                    {(t("navBarButton.dropDownMenuLinks", { returnObjects: true }) as DropDownLinks[]).map((voice, index) => (
                      <Link key={index} className="text-white" to={`/${voice.link}`}  onClick={() => setOpenHamburger(!openHamburger)}>
                        {voice.name}
                      </Link>
                    ))}
                  </nav>
                )}
              </div>
            </nav>
            <div ref={languageRef} className="flex flex-col gap-2 pl-20">
              <button className="flex items-center gap-2" onClick={() => setLanguageModalOpen(!languageModalOpen)}>
                <img src={currentLanguageIcon} alt="Current Language" />
                {currentLanguageIcon === italianLanguageIcon ? <span className="font-poppins text-xl text-white font-semibold uppercase">Ita</span> : <span className="font-poppins text-xl text-white font-semibold uppercase">Eng</span>}
                {languageModalOpen ? <img className="rotate-180" src={ChevronDown} alt="Chevron Up" /> : <img src={ChevronDown} alt="Chevron Down" />}
              </button>
              {languageModalOpen && (
                <div className="font-poppins font-semibold">
                  {(t("languages", { returnObjects: true }) as Choice[]).map((choice, index) => (
                    <button className="flex gap-4 py-2" key={index} onClick={() => changeLanguage(choice)}>
                      <img src={choice.icon} alt="Language Icon" />
                      <span className="font-poppins text-white font-semibold">{choice.language}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <Button text={t('navBarButton.login')} isColoured={false} link="/login" />
              <Button text={t('navBarButton.onBoarding')} isColoured={false} link="/onboarding" />
            </div>
          </div>
        </div>
    );
};
