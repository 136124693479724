import React from "react";
import { useTranslation } from "react-i18next";
import restaurantsClubLogo from "@images/restaurants-club-logo-white.png";
import InstagramIcon from "@images/instagram-icon.png";
import LinkedinIcon from "@images/linkedin-icon.png";
import FacebookIcon from "@images/facebook-icon.png";
import YoutubeIcon from "@images/youtube-icon.png";
import CodiceEtico from "@docs/it/ethic-code.pdf";
import CookiePolicy from "@docs/it/cookie-policy.pdf";
import PrivacyPolicy from "@docs/it/privacy-policy.pdf";
import TermsConditions from "@docs/it/terms-and-conditions.pdf";

export const Footer = () => {
  const { t } = useTranslation();
  const { 
    REACT_APP_WHATSAPP_NUMBER: whatsappNumber,
    REACT_APP_WHATSAPP_TEXT2: whatsappText1,
    REACT_APP_WHATSAPP_TEXT2: whatsappText2,
    REACT_APP_WHATSAPP_TEXT3: whatsappText3,
    REACT_APP_WHATSAPP_TEXT6: whatsappText6,
    REACT_APP_WHATSAPP_TEXT7: whatsappText7,
   } = process.env;

  return (
    <div className="bg-darkblue700 px-4 py-5">
      <div className="w-full flex flex-col gap-6  md:w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row mx-auto">
        <div className=" w-full">
          <div>
            <img src={restaurantsClubLogo} alt="Restaurants Club Logo" className="w-60" />
          </div>
          <p className="font-poppins text-md font-regular text-gray200 my-5 xmd:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[60%] xmd:my-20 lg:my-20 xl:my-20 2xl:my-20">
            {t("footer.subtitle")}
          </p>
        </div>
        <div className="w-full flex flex-col gap-6  xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row xmd:p-5 lg:p-5 xl:p-5 2xl:p-5">
          <div className="flex flex-col gap-y-2 my-5 flex-grow">
            <p className="font-poppins text-sm font-semibold text-gray300 mb-3">
              {t("footer.aitool.title")}
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={`https://wa.me/${whatsappNumber}?text=${whatsappText2}`} target="_blank">{t("footer.aitool.1")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={`https://wa.me/${whatsappNumber}?text=${whatsappText1}`} target="_blank">{t("footer.aitool.2")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={`https://wa.me/${whatsappNumber}?text=${whatsappText3}`} target="_blank">{t("footer.aitool.3")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={`https://wa.me/${whatsappNumber}?text=${whatsappText6}`} target="_blank">{t("footer.aitool.4")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={`https://wa.me/${whatsappNumber}?text=${whatsappText7}`} target="_blank">{t("footer.aitool.5")}</a>
            </p>

          </div>
          <div className="flex flex-col gap-y-2 my-5 mb-10 flex-grow">
            <p className="font-poppins text-sm font-semibold text-gray300">
              {t("footer.info.title")}
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={PrivacyPolicy} target="_blank">{t("footer.info.1")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={CookiePolicy} target="_blank">{t("footer.info.2")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={CodiceEtico} target="_blank">{t("footer.info.3")}</a>
            </p>
            <p className="font-poppins text-md font-semibold text-gray200">
              <a href={TermsConditions} target="_blank">{t("footer.info.4")}</a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-6 border-t border-gray600 mt-6  md:w-[90%]  xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto xmd:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse 2xl:flex-row-reverse pt-5">
        <div className="flex flex-row gap-6 pt-6 w-full xmd:w-[30%] lg:w-[30%] xl:fw-[30%] 2xl:w-[30%] xmd:justify-end lg:justify-end xl:justify-end 2xl:justify-end xmd:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0">
          <a href="https://www.instagram.com/restaurants.club/" target="_blank">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/products/aigot-srl-restaurants-club/" target="_blank">
            <img src={LinkedinIcon} alt="Linkedin" />
          </a>
          <a href="https://www.facebook.com/restaurantsclubitalia" target="_blank">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.youtube.com/@restaurantsclub" target="_blank">
            <img src={YoutubeIcon} alt="Youtube" />
          </a>
        </div>
        <div className="flex w-full">
          <p className="font-poppins text-md font-regular text-gray300">
            {t("footer.copyright")}
          </p>
        </div>
      </div>
    </div>
  );
};
