import React from "react";
import { useTranslation } from "react-i18next";
import { TextBanner } from "../../reusable/text-banner";
import { IconList } from "../../reusable/icon-list";
import Circleaccounticon from "@images/circle-account-icon.png";
import Circlechaticon from "@images/circle-chat-icon.png";
import Circlemissionicon from "@images/circle-mission-icon.png";
import { PricingCard } from "./pricing-card";

export const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="py-5 px-6 md:w-[90%] xmd:w-[95%] lg:w-[90%] xl:w-[85%] 2xl:w-[85%] justify-center items-center mx-auto">
      <TextBanner
        title={t("pricing.title")}
        paragraph={t("pricing.text")}
      />
      <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-20 lg:gap-5">
        <div className="flex justify-center items-center">
          <IconList
            features={[
              { icon: Circleaccounticon, title: t("pricing.info1.title"), text: t("pricing.info1.text") },
              { icon: Circlechaticon, title: t("pricing.info2.title"), text: t("pricing.info2.text") },
              { icon: Circlemissionicon, title: t("pricing.info3.title"), text: t("pricing.info3.text") }
            ]}
          />
        </div>
        <PricingCard />
      </div>
    </div>
  );
};
