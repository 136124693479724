import { useTranslation } from "react-i18next"
import { Button } from "../../reusable/button"
import webSiteImg from "@images/website.png"
import advertisementImg from "@images/advertisement.png"
import socialMediaImg from "@images/social-media.png"
import monitorIconImg from "@images/monitor-icon.png"
import rocketIconImg from "@images/rocket-green-icon.svg"
import heartIconImg from "@images/heart-icon.png"
import checkIconImg from "@images/check-icon.png"

export const FeaturesSection = () => {
    const { t } = useTranslation()
    return (
        <div className="w-full flex justify-center">
          <div className="w-full max-w-[2434px] py-16 flex flex-col gap-12 items-center mt-32 md:px-4 sm:px-4 xsm:px-4">
            <h2 className="text-gray900 text-center text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase">
              {t("featuresSectionTexts.title")}
            </h2>
            <div className="flex flex-col w-full items-start gap-10 self-stretch lg:flex-row lg:items-center xl:flex-row xl:items-center 2xl:flex-row 2xl:items-center">
              <div className="w-full flex justify-center xmd:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <div className="flex flex-col items-start justify-center gap-6 p-4 self-stretch max-w-[700px]">
                  <div className="bg-primary100 p-2 rounded-full">
                    <img src={monitorIconImg} alt="Monitor icon" />
                  </div>
                  <div>
                    <h3 className="self-stretch text-gray900 font-brevia text-display-sm font-semibold uppercase mb-6">
                      {t("featuresSectionTexts.titleParagraph1")}
                    </h3>
                    <p className="self-stretch text-gray600 font-poppins text-lg font-regular">
                      {t("featuresSectionTexts.paragraph1")}
                    </p>
                  </div>
                  <ul className="flex flex-col gap-2 px-3">
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-[28px] h-[28px]" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list1Item1")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-[28px] h-[28px]" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list1Item2")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular">
                      <img className="w-[28px] h-[28px]" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list1Item3")}
                    </li>
                  </ul>
                  <Button text={t("heroProductsSectionTexts.button2")} isColoured={true}  link="#hubspotForm"/>
                </div>
              </div>
              <div className="w-full h-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <img className="object-cover w-full h-full" src={webSiteImg} alt="Background" />
              </div>
            </div>
            <div className="flex flex-col w-full items-start gap-10 self-stretch lg:flex-row-reverse lg:items-center xl:flex-row-reverse xl:items-center 2xl:flex-row-reverse 2xl:items-center">
              <div className="w-full flex justify-center xmd:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <div className="flex flex-col items-start justify-center gap-6 p-4 self-stretch max-w-[700px]">
                  <div className="bg-primary100 p-2 rounded-full">
                    <img src={rocketIconImg} alt="Rocket icon" />
                  </div>
                  <div>
                    <h3 className="self-stretch text-gray900 font-brevia text-display-sm font-semibold uppercase mb-6">
                      {t("featuresSectionTexts.titleParagraph2")}
                    </h3>
                    <p className="self-stretch text-gray600 font-poppins text-lg font-regular">
                      {t("featuresSectionTexts.paragraph2")}
                    </p>
                  </div>
                  <ul className="flex flex-col gap-2 px-3">
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list2Item1")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list2Item2")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list2Item3")}
                    </li>
                  </ul>
                  <Button text={t("heroProductsSectionTexts.button2")} isColoured={true}  link="#hubspotForm"/>
                </div>
              </div>
              <div className="w-full h-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <img className="object-cover w-full h-full" src={advertisementImg} alt="Background" />
              </div>
            </div>
            <div className="flex flex-col w-full items-start gap-10 self-stretch lg:flex-row lg:items-center xl:flex-row xl:items-center 2xl:flex-row 2xl:items-center">
              <div className="w-full flex justify-center xmd:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <div className="flex flex-col items-start justify-center gap-6 p-4 self-stretch max-w-[700px]">
                  <div className="bg-primary100 p-2 rounded-full">
                    <img src={heartIconImg} alt="Heart icon" />
                  </div>
                  <div>
                    <h3 className="self-stretch text-gray900 font-brevia text-display-sm font-semibold uppercase mb-6">
                      {t("featuresSectionTexts.titleParagraph3")}
                    </h3>
                    <p className="self-stretch text-gray600 font-poppins text-lg font-regular">
                      {t("featuresSectionTexts.paragraph3")}
                    </p>
                  </div>
                  <ul className="flex flex-col gap-2 px-3">
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list3Item1")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular mb-4">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list3Item2")}
                    </li>
                    <li className="flex gap-2 self-stretch text-gray600 font-poppins text-lg font-regular">
                      <img className="w-7 h-7" src={checkIconImg} alt="Check" />
                      {t("featuresSectionTexts.list3Item3")}
                    </li>
                  </ul>
                  <Button text={t("heroProductsSectionTexts.button2")} isColoured={true}  link="#hubspotForm"/>
                </div>
              </div>
              <div className="w-full h-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
                <img className="object-cover w-full h-full" src={socialMediaImg} alt="Background" />
              </div>
            </div>
          </div>
        </div>
    )
}
