import starReviw from "@images/star-review-icon.svg"

interface IReview {
    review: string;
    name: string;
}

export const Review = ({review, name}: IReview) => {
    return (
        <div className="max-w-[384px] h-[226px] p-[24px] flex flex-col justify-center items-start gap-[10px] border-2 border-gray200 rounded-[10px] bg-white">
            <p className="font-poppins font-regular text-[12px]">
                {review}
            </p>
            <h4 className="font-poppins font-medium text-[14px]">
                {name}
            </h4>
            <div className="flex">
                <img src={starReviw} alt="Star" />
                <img src={starReviw} alt="Star" />
                <img src={starReviw} alt="Star" />
                <img src={starReviw} alt="Star" />
                <img src={starReviw} alt="Star" />
            </div>
        </div>
    )
}
