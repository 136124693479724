import restaurantsClubLogo from "@images/restaurants-club-logo.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";
import ChevronDown from "@images/chevron-down.svg";
import ChevronUp from "@images/chevron-up.svg";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type MenuVoice = {
  name: string;
};

type TranslateRoutes = {
  it: string;
  en: string;
};

type DropDownLinks = {
  name: string;
  link: string;
};

type Choice = {
    lng: string;
    icon: string;
    language: string;
};

export const NavBar = () => {
  const [openDropDownMenu, setOpenDropDownMenu] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(italianLanguageIcon);
  const { t, i18n } = useTranslation();
  const dropDownRef = useRef<HTMLDivElement>(null);
  const languageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

    const changeLanguage = (choice: Choice) => {
        i18n.changeLanguage(choice.lng);
        setCurrentLanguageIcon(choice.icon);
        setLanguageModalOpen(false);
      };

  useEffect(() => {
    // To avoid the redirect. TODO: Solve in the future
    const path = window.location.pathname.split('/').pop();
    const menuVoices = t("translateRoutes", { returnObjects: true }) as TranslateRoutes[];
  
    const matchedVoiceIndex = menuVoices.findIndex((voice) => 
      (i18n.language === 'it' && voice.en === path) || (i18n.language === 'en' && voice.it === path));
  
    if (matchedVoiceIndex !== -1) {
      const translatePath = i18n.language === 'it' ? menuVoices[matchedVoiceIndex].it : menuVoices[matchedVoiceIndex].en;
      navigate(`/${translatePath}`);
    }
  }, [i18n.language]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
        setOpenDropDownMenu(false);
      }
      if (languageRef.current && !languageRef.current.contains(event.target as Node)) {
        setLanguageModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full fixed top-0 flex justify-between items-center p-3 bg-white z-50 xmd:p-1 md:hidden sm:hidden xsm:hidden">
      <div className="flex gap-7 items-center xmd:gap-5">
        <img className="xmd:w-[150px]" src={restaurantsClubLogo} alt="Restaurants Club Logo" />
        <nav className="flex gap-16 items-center font-poppins text-md text-gray600 font-semibold xmd:gap-5 xmd:text-sm">
          {(t("navBarMenuVoices", { returnObjects: true }) as MenuVoice[]).map((voice, index) => (
            voice.name.toLowerCase() === "blog" ? (
              <Link to="https://blog.restaurants.club/blog/#" key={index} className="">
                {voice.name}
              </Link>
            ) : (
              <Link to={`/${voice.name.toLowerCase()}`} key={index} className="">
                {voice.name}
              </Link>
            )
          ))}
          <div className="relative" ref={dropDownRef}>
            <button onClick={() => setOpenDropDownMenu(!openDropDownMenu)} className="">
              {t("navBarButton.dropDownMenu")}
            </button>
            <nav className={`absolute left-0 flex flex-col gap-1 mt-2 p-1 bg-white rounded-md transition-transform duration-300 ease-in-out transform origin-top ${openDropDownMenu ? 'scale-y-100' : 'scale-y-0'}`}>
              {(t("navBarButton.dropDownMenuLinks", { returnObjects: true }) as DropDownLinks[]).map((voice, index) =>
                <Link key={index} className="text-primary500" to={`/${voice.link}`}>
                  {voice.name}
                </Link>
              )}
            </nav>
          </div>
        </nav>
      </div>
      <div className="flex gap-7 items-center xmd:gap-2">
        <div className="flex gap-2 items-center">
          <button className="w-40 py-2 rounded border border-gray300 text-gray700 bg-white font-poppins text-md font-semibold lg:w-28 lg:text-sm xmd:w-24 xmd:text-xs">{t('navBarButton.onBoarding')}</button>
          <button className="w-40 py-2 rounded bg-darkblue700 text-white font-poppins text-md font-semibold lg:w-28 lg:text-sm xmd:w-14 xmd:text-xs">{t('navBarButton.login')}</button>
        </div>
        <div ref={languageRef}>
          <button className="w-12 flex items-center" onClick={() => setLanguageModalOpen(!languageModalOpen)}>
            <img src={currentLanguageIcon} alt="Current Language" />
            {languageModalOpen ? <img src={ChevronUp} alt="Chevron Up" /> : <img src={ChevronDown} alt="Chevron Down" />}
          </button>
          {languageModalOpen && (
            <div className="absolute z-50 right-0 top-14 bg-white w-32 font-poppins font-semibold">
              {(t("languages", { returnObjects: true }) as Choice[]).map((choice, index) => (
                <button className="flex gap-4 items-center px-4 py-2" key={index} onClick={() => changeLanguage(choice)}>
                  <img src={choice.icon} alt="Language Icon" />
                  <span>{choice.language}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
