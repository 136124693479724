import React from "react";
import { useTranslation } from "react-i18next";
import Step1 from "@images/step-1.png";
import Step2 from "@images/step-2.png";
import Step3 from "@images/step-3.png";
import { Button } from "../../reusable/button";

export const Steps = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full mb-10 text-center px-10 xsm:w-[90%] sm:w-[90%] md:w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto">
      <h3 className="xsm:block sm:block font-brevia text-display-md xsm:text-display-sm sm:text-display-sm md:text-display-sm font-semibold text-gray900 mt-6 uppercase my-8">
        {t("percentageSectionTexts.title")}
      </h3>
      <div className="flex flex-row xsm:flex-col sm:flex-col md:flex-col justify-center gap-10">
        {[Step1, Step2, Step3].map((step, index) => (
          <div key={index} className="flex flex-col items-center w-full mx-auto">
            <div className="flex items-center justify-center w-full h-48">
              <img src={step} alt={`Step ${index + 1}`} className="max-h-full xsm:[70%] sm:[70%]" />
            </div>
            <p className="xsm:block sm:block font-poppins text-xl font-semibold text-gray900 mt-5">
              {t(`percentageSectionTexts.paragraphPart.${index + 1}`)}
            </p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center my-12">
        <Button id="heroButton" text={t("heroHeaderSectionTexts.button")} isColoured={true} link="#hubspotForm" />
      </div>
    </div>
  );
};
