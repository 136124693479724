import { Form } from "../hubspot-form/hubspot-from";
import { FeaturesSection } from "./features-section/features-section";
import { ForRestaurateurs } from "./for-restaurateurs/for-restaurateurs";
import { HeroProducts } from "./hero-products-section/hero-products";
import { FaqSection } from "./faq-section/faq-section";
import { ReviewsSection } from "./reviews-section/reviews-section";
import { WeKnowIt } from "./we-know-it/we-know-it";
import greenWavesImg from "@images/for-restaurateurs-green-waves.png";

export const Prodotto: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
  return (
    <>
      <div className='w-full flex flex-col items-center gap-32 md:gap-16 sm:gap-16 xsm:gap-16'>
        <HeroProducts />
        <WeKnowIt />
        <ForRestaurateurs />
        <div className="w-full relative mt-10">
          <img className="absolute right-0" src={greenWavesImg} alt="Green waves" />
        </div>
        <FeaturesSection />
        <div className="w-full relative mt-10">
          <img className="absolute left-0 rotate-180 scale-y-[-1]" src={greenWavesImg} alt="Green waves" />
        </div>
        <ReviewsSection />
        <FaqSection />
        <div className="w-full max-w-[2434px]">
          <Form />
        </div>
      </div>
    </>
  );
};
