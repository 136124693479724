import React from "react";
import { Button } from "../../reusable/button";
import heroImg from "@images/hero-img.png";
import { useTranslation } from "react-i18next";

export const HeroHeader = () => {
  const { t } = useTranslation();
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <>
      <div className="w-full flex flex-col xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row xmd:px-20 px-32 xsm:px-5 sm:p-7 md:p-7 xmd:py-10">
        <div className="w-[60%] xsm:w-full sm:w-full md:w-full xmd:w-full flex justify-center flex-col md:px-8">
          <div className="text-left">
            <h1 className="text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase">
              {t("heroHeaderSectionTexts.title")}
            </h1>
            <div className="my-4 w-[70%] xsm:w-full sm:w-full md:w-full">
              <p className="text-gray600 text-xl  xsm:text-lg sm:text-lg md:text-lg font-regular font-poppins ">
                {t("heroHeaderSectionTexts.paragraphPart1")}
              </p>
            </div>
            <div className="py-7 font-poppins font-regular">
              <div className="flex flex-col xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-3 text-md font-semibold">
                <Button id="heroButton" text={t("heroHeaderSectionTexts.button")} isColoured={true} />
                <Button id="freeTrialCardButton" text={t("heroHeaderSectionTexts.button2")} isColoured={false} link="#hubspotForm" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%] xsm:w-full sm:w-full md:w-full flex justify-center items-center">
          <img className="" src={heroImg} alt="Macbook" />
        </div>
      </div>
    </>
  );
};
