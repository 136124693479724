import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../reusable/button";
import LaDolceVita from "@images/ladolce-vita.png";
import LaDolceVitaMob from "@images/ladolcevita-mob.png";
import Smile from "@images/smile.png";

const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

export const FreeTrial = () => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-primary500 text-white rounded-2xl py-10 px-10 flex flex-col md:pb-0 xsm:pb-0 sm:pb-0 xsm:w-[90%] sm:w-[90%] md:w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto mt-28 xsm:mt-7 sm:mt-7 md:mt-7 xmd:mt-12">
      <div className="relative text-white flex flex-col gap-5 xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
        <div className="w-[50%] lg:w-full xsm:w-full sm:w-full md:w-full xmd:w-full lg:p-12 xl:p-12 2xl:p-12">
          <h3 className="font-brevia text-display-md xsm:text-display-sm sm:text-display-sm xmd:text-display-sm font-semibold uppercase">
            {t("freetrialCardTexts.title")}
          </h3>
          <p className="font-poppins text-xl xmd:text-lg lg:w-[54%] xsm:text-lg sm:text-lg font-normal my-12 mt-5 text-primary200">
            {t("freetrialCardTexts.subtitle")}
          </p>
          <Button id="freeTrialCardButton1" text={t("freetrialCardTexts.button.1")} isColoured={false} link="#hubspotForm" ></Button>
        </div>
        <div className="w-[55%] xsm:w-full sm:w-full md:w-full xmd:w-[85%] lg:w-[46%] lg:absolute lg:top-28 lg:right-0 lg:z-20">
          <img src={LaDolceVita} alt="" className="xsm:hidden sm:hidden md:hidden" />
          <img src={LaDolceVitaMob} alt="" className="mx-auto xmd:hidden lg:hidden xl:hidden 2xl:hidden" />
        </div>
        <div className="absolute w-full h-full">
          <img src={Smile} className="absolute -top-56 -right-16 md:-top-28 xmd:-top-36 xsm:-top-28 sm:-top-28 xmd:w-40 md:w-40 xsm:w-40 sm:w-40" />
        </div>
      </div>
    </div>
  );
};
