import React, { useState } from 'react';
import './accordion-style.css'

type Faq = {
  question: string;
  answer: string;
};

interface AccordionProps {
  faqs: Faq[];
}

export const Accordion: React.FC<AccordionProps> = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number): void => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {faqs.map((faq: Faq, index: number) => (
        <div key={index} className="border-b border-gray200 my-5 font-poppins">
          <div
            className="cursor-pointer flex justify-between items-center my-4"
            onClick={() => toggleAccordion(index)}>
            <p className="text-lg font-medium w-[80%]">{faq.question}</p>
            <div className="rounded-full border-2 border-gray400 w-[20px] h-[20px] flex justify-center items-center">
              <p className="text-gray400 text-sm font-regular">
                {openIndex === index ? '-' : '+'}
              </p>
            </div>
          </div>
          <div className={`accordion-content ${openIndex === index ? 'open' : ''} mb-5 text-gray600`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
