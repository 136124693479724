import { useTranslation } from "react-i18next";
import { Button } from "../../reusable/button";
import { BannerNews } from "../../reusable/banner-news";

export const HeroProducts = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center gap-4 px-20 max-w-[1634px] md:px-4 sm:px-4 xsm:px-4">
      <BannerNews />
      <h1 className="text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase text-center">
        {t("heroProductsSectionTexts.title")}
      </h1>
      <p className="text-gray600 text-xl xsm:text-lg sm:text-lg md:text-lg font-regular font-poppins text-center">
        {t("heroProductsSectionTexts.paragraphPart1")}
      </p>
      <div className="flex w-full justify-center gap-3 md:flex-col sm:flex-col xsm:flex-col">
        <Button text={t("heroProductsSectionTexts.button")} isColoured={true} />
        <Button text={t("heroProductsSectionTexts.button2")} isColoured={false} link="#hubspotForm" />
      </div>
    </div>
  );
};
