import dotNews from "@images/dot-news.png";
import { useTranslation } from "react-i18next";

export const BannerNews = () => {
  const { t } = useTranslation();
  return (
      <div className="flex p-[4px_10px_4px_4px] items-center gap-2 rounded-[10px] border border-[#D0D5DD] bg-[#FFF] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] mb-[10px] text-start">
        <div className="rounded-[10px] border border-[#D0D5DD] flex gap-1 items-center p-1 min-w-[70px]">
          <img src={dotNews} alt="Dot" />
          <span className="font-poppins text-[12px] text-gray700">
            {t("heroProductsSectionTexts.bannerNews")}
          </span>
        </div>
        <p className="font-poppins text-[12px] text-gray700">
          {t("heroProductsSectionTexts.paragraphBanner")}
        </p>
      </div>
  );
};
