import HubSpotForm from "react-hubspot-form";
import { useTranslation } from "react-i18next";
import mailIcon from "@images/contacts-mail-icon.svg"
import markerIcon from "@images/contacts-marker-pin-icon.svg"
import messageIcon from "@images/contacts-message-chat-icon.svg"
import phoneIcon from "@images/contacts-phone-icon.svg"
import iconImg from '@images/icon-contact-page.png'

export const ContactsPageComponent: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
  const { t } = useTranslation();
  const { 
    REACT_APP_HUBSPOT_PORTAL_ID: portalId,
    REACT_APP_HUBSPOT_FORM_CONTACTS_ID: formId,
    REACT_APP_WHATSAPP_NUMBER: whatsAppNumber,
    REACT_APP_PHONE_NUMBER: phoneNumber,
    REACT_APP_WHATSAPP_TEXT5: whatsAppText
   }  = process.env;

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[1634px] w-full flex flex-col items-center p-20 gap-[64px] md:p-10 sm:p-5 xsm:p-5">
        <div className="w-full flex justify-between">
          <div className="w-full flex flex-col gap-5">
            <span className="text-primary700 font-semibold text-md font-poppins">
              {t("contactsPageTexts.preTitle")}
            </span>
            <h1 className="text-gray900 font-semibold text-display-md font-brevia uppercase">
              {t("contactsPageTexts.title")}
            </h1>
            <p className="text-gray600 font-regular text-lg font-poppins">
              {t("contactsPageTexts.paragraphAfterTitle")}
            </p>
          </div>
          <img className="xmd:hidden md:hidden sm:hidden xsm:hidden" src={iconImg} alt="Icon" />
        </div>
        <div className="w-full flex gap-[64px] xmd:flex-col-reverse md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse">
          <div className="w-[50%] flex flex-col gap-[48px] xmd:w-full md:w-full sm:w-full xsm:w-full">
            <div className="flex gap-[32px] xmd:w-full xmd:justify-center md:flex-col sm:flex-col xsm:flex-col">
              <div className="flex flex-col gap-[16px] w-[300px]">
                <img className="w-[25px] h-[25px]" src={mailIcon} alt="Mail icon" />
                <h2 className="text-gray900 font-semibold text-xl font-poppins">
                  {t("contactsPageTexts.titleParagraph1")}
                </h2>
                <p className="text-gray600 font-regular text-md font-poppins">
                  {t("contactsPageTexts.paragraph1")}
                </p>
                <a
                  href={`mailto:${t("contactsPageTexts.linkParagraph1")}`}
                  className="text-primary700 font-semibold text-md font-poppins"
                >
                  {t("contactsPageTexts.linkParagraph1")}
                </a>
              </div>
              <div className="flex flex-col gap-[16px] w-[300px]">
                <img className="w-[25px] h-[25px]" src={messageIcon} alt="Message icon" />
                <h2 className="text-gray900 font-semibold text-xl font-poppins">
                  {t("contactsPageTexts.titleParagraph2")}
                </h2>
                <p className="text-gray600 font-regular text-md font-poppins">
                  {t("contactsPageTexts.paragraph2")}
                </p>
                <a
                  href={`https://wa.me/${whatsAppNumber}?text=${whatsAppText}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary700 font-semibold text-md font-poppins"
                >
                  {t("contactsPageTexts.linkParagraph2")}
                </a>
              </div>
            </div>
            <div className="flex gap-[32px] xmd:w-full xmd:justify-center md:flex-col sm:flex-col xsm:flex-col">
              <div className="flex flex-col gap-[16px] w-[300px]">
                <img className="w-[25px] h-[25px]" src={markerIcon} alt="Marker icon" />
                <h2 className="text-gray900 font-semibold text-xl font-poppins">
                  {t("contactsPageTexts.titleParagraph3")}
                </h2>
                <p className="text-gray600 font-regular text-md font-poppins">
                  {t("contactsPageTexts.paragraph3")}
                </p>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=Via+Santa+Maria+25,+56126+Pisa"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary700 font-semibold text-md font-poppins block"
                >
                  {t("contactsPageTexts.linkParagraph3")}
                </a>
              </div>
              <div className="flex flex-col gap-[16px] w-[300px]">
                <img className="w-[25px] h-[25px]" src={phoneIcon} alt="Phone icon" />
                <h2 className="text-gray900 font-semibold text-xl font-poppins">
                  {t("contactsPageTexts.titleParagraph4")}
                </h2>
                <p className="text-gray600 font-regular text-md font-poppins">
                  {t("contactsPageTexts.paragraph4")}
                </p>
                <div className="flex flex-col gap-2">
                  <a href={`tel:${whatsAppNumber}`} className="text-primary700 font-semibold text-md font-poppins">
                    {whatsAppNumber}
                  </a>
                  <a href={`tel:${phoneNumber}`} className="text-primary700 font-semibold text-md font-poppins">
                    {phoneNumber}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[50%] xmd:w-full md:w-full sm:w-full xsm:w-full">
            <HubSpotForm
              portalId={portalId}
              formId={formId}
              loading={<div>{t("hubspots.loading")}</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
