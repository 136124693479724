import { useTranslation } from "react-i18next";
import forRestaurateursLightningImg from "@images/for-restaurateurs-lightning-icon.png";
import forRestaurateursMessagesImg from "@images/for-restaurateurs-messages-icon.png";

export const ForRestaurateurs = () => {
  const { t } = useTranslation();
  return (
      <div className="w-full pt-16 pb-28 bg-primary50 flex justify-center">
        <div className="max-w-[1634px] px-20 flex flex-col gap-12 lg:flex-row lg:justify-center xl:flex-row xl:justify-center 2xl:flex-row 2xl:justify-center md:px-4 sm:px-4 xsm:px-4">
          <h2 className="text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase">
            {t("forRestaurateursSectionTexts.title")}
          </h2>
          <div className="w-1/2 flex flex-col gap-12 xmd:w-full md:w-full sm:w-full xsm:w-full">
            <div className="flex gap-4">
              <img className="w-10 h-10" src={forRestaurateursMessagesImg} alt="Messages Icon" />
              <div className="w-full flex flex-col">
                <h3 className="font-poppins text-lg font-semibold text-gray900">
                  {t("forRestaurateursSectionTexts.titleParagraph1")}
                </h3>
                <p className="text-gray600 font-poppins text-md font-regular">
                  {t("forRestaurateursSectionTexts.paragraph1")}
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <img className="w-10 h-10" src={forRestaurateursLightningImg} alt="Lightning Icon" />
              <div className="w-full flex flex-col">
                <h3 className="font-poppins text-lg font-semibold text-gray900">
                  {t("forRestaurateursSectionTexts.titleParagraph2")}
                </h3>
                <p className="text-gray600 font-poppins text-md font-regular">
                  {t("forRestaurateursSectionTexts.paragraph2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
