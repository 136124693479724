import { useTranslation } from "react-i18next";
import { Pricing } from "../home/pricing-section/pricing";
import { Marquee } from "../home/banner-scroll/banner-scroll";
import HubSpotForm from "react-hubspot-form";
import { Accordion } from "../reusable/accordion";
import bgIconImg from '@images/bg-for-icon-faq-product.svg';
import iconImg from '@images/icon-faq-product.png';
import iconPricing from '@images/prices-page-icon1.png';
import iconFAQS from '@images/prices-page-icon2.png';

export const PricesPageComponent: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
    const { t } = useTranslation();
    const { 
        REACT_APP_HUBSPOT_PORTAL_ID: portalId,
        REACT_APP_HUBSPOT_FORM_PRICES_ID: formId
       }  = process.env;
    const faqs = [
      {
        question: t("faqSectionTexts.question1"),
        answer: t("faqSectionTexts.answer1"),
      },
      {
        question: t("faqSectionTexts.question2"),
        answer: t("faqSectionTexts.answer2"),
      },
      {
        question: t("faqSectionTexts.question3"),
        answer: t("faqSectionTexts.answer3"),
      },
      {
        question: t("faqSectionTexts.question4"),
        answer: t("faqSectionTexts.answer4"),
      },
      {
        question: t("faqSectionTexts.question5"),
        answer: t("faqSectionTexts.answer5"),
      }
    ];

    return (
        <div className="w-full flex flex-col gap-10 items-center overflow-hidden">
          <div className="flex flex-col gap-10 items-center">
            <div className="relative flex flex-col items-center px-10">
              <img className="absolute top-0 -right-20 xmd:hidden md:hidden sm:hidden xsm:hidden" src={bgIconImg} alt="Background icon" />
              <img className="absolute top-3 -right-16 xmd:hidden md:hidden sm:hidden xsm:hidden" src={iconImg} alt="Icon" />
              <h1 className="text-gray900 font-bold text-display-md font-brevia uppercase text-center">
                Plans for all sizes
              </h1>
              <p className="text-gray600 font-regular text-xl font-poppins text-center">
                Simple, transparent pricing that grows with you. Try any plan free for 30 days.
              </p>
            </div>
            <div className="relative">
                <img className="absolute bottom-0 xmd:hidden md:hidden sm:hidden xsm:hidden" src={iconPricing} alt="Icon" />
              <Pricing />
            </div>
          </div>
          <div className='w-full'>
            <Marquee />
          </div>
          <div id="hubspotForm" className="max-w-[1635px] w-full mt-24 px-10 flex flex-col items-center gap-10">
            <h2 className="text-gray900 font-bold text-display-md font-brevia uppercase text-center">
              Richiedi la tua <span className="text-primary500">consulenza gratuita</span> ora!
            </h2>
            <div className="w-full max-w-[1240px]">
              <HubSpotForm
                portalId={portalId}
                formId={formId}
                loading={<div>{t("hubspots.loading")}</div>}
              />
            </div>
          </div>
          <div className='w-full'>
            <Marquee />
          </div>
          <div className="w-full max-w-[1240px] px-10 pb-10 mt-24 flex gap-10 xmd:flex-col md:flex-col sm:flex-col xsm:flex-col">
            <div className="relative flex flex-col gap-5 w-1/2 xmd:w-full md:w-full sm:w-full xsm:w-full">
              <img className="absolute bottom-32 xmd:hidden md:hidden sm:hidden xsm:hidden" src={iconFAQS} alt="Icon" />
              <div>
                <span className="text-darkblue700 font-semibold text-md font-poppins">
                  Support
                </span>
                <h2 className="text-gray900 font-bold text-display-md font-brevia uppercase">
                  FAQs
                </h2>
              </div>
              <p className="text-gray600 font-regular text-xl font-poppins">
                Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Please chat to our friendly team.
              </p>
            </div>
            <div className="w-1/2 xmd:w-full md:w-full sm:w-full xsm:w-full">
              <Accordion faqs={faqs} />
            </div>
          </div>
        </div>
    );
};
