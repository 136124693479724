import React from "react";
import { useTranslation } from "react-i18next";
import { TextBanner } from "../../reusable/text-banner";
import { ContactOptionsCard } from "./contact-options-card";
import SmileChatIcon from "@images/lightbulb.svg";
import ChatIcon from "@images/marker.svg";
import Rocket from "@images/rocket.svg";
import User from "@images/user.svg";

export const ContactOptions = () => {
  const { t } = useTranslation();

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;

  return (
    <>
      <div className="py-10  md:w-[90%] xmd:w-[85%] lg:w-[95%] xl:w-[85%] 2xl:w-[85%] justify-center items-center mx-auto">
        <TextBanner
          title={t("contact.title")}
          paragraph={t("contact.text")}
        />
        <div className="justify-center items-center mx-auto">
          <ContactOptionsCard
            features={[
              {
                icon: SmileChatIcon,
                text1: t("contact.info1.title"),
                text2: t("contact.info1.subtitle"),
                text3: t("contact.info1.text"),
                link: `https://wa.me/${whatsappNumber}?text=${process.env.REACT_APP_WHATSAPP_TEXT1}`,
                id: "emailButton",
              },
              {
                icon: ChatIcon,
                text1: t("contact.info2.title"),
                text2: t("contact.info2.subtitle"),
                text3: t("contact.info2.text"),
                link: `https://wa.me/${whatsappNumber}?text=${process.env.REACT_APP_WHATSAPP_TEXT2}`,
                id: "whatsappButton",
              },
              {
                icon: User,
                text1: t("contact.info3.title"),
                text2: t("contact.info3.subtitle"),
                text3: t("contact.info3.text"),
                link: `https://wa.me/${whatsappNumber}?text=${process.env.REACT_APP_WHATSAPP_TEXT3}`,
                id: "mapsButton",
              },
              {
                icon: Rocket,
                text1: t("contact.info4.title"),
                text2: t("contact.info4.subtitle"),
                text3: t("contact.info4.text"),
                link: `https://wa.me/${whatsappNumber}?text=${process.env.REACT_APP_WHATSAPP_TEXT4}`,
                id: "rocketButton",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
